






import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'Docs',
})
export default class Docs extends Vue {
}
