

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Logger } from '@/tools/Logger';
import PopoutButton from '@/views/Dashboard/Docs/components/PopoutButton.vue';

@Component({
  name: 'Markdown',
  components: {
    PopoutButton,
  },
})
export default class Markdown extends Vue {
  @Prop({ required: false, default: false })
  protected readonly popped!: boolean;

  @Prop({ required: true, default: '' })
  private readonly doc!: string;

  protected html = '';

  /**
   * Resolved router link to open in popout
   */
  protected popoutHref = '';

  private readonly logger: Logger = new Logger({ context: 'Markdown' });

  public created() {
    try {
      if (!this.doc) throw Error('Unable to render view without `doc` prop');
      this.html = require(`@/docs/${this.doc}.md`);
    } catch (error) {
      this.logger.error(error);
      this.$router.push({ name: 'error' });
    }
  }

  public mounted() {
    const resolved = this.$router.resolve({
      name: 'docs-md',
      params: {
        doc: this.doc,
      },
      query: {
        popped: 'yes',
      },
    });
    this.popoutHref = resolved.href;
  }
}
